import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroSection from "../components/hero-section"

const Privacy = () => (
  <Layout>
    <SEO title="Privacy and Legal" />
    <HeroSection backgroundColor="#313d36">
      <div className="flex flex-col text-center items-center justify-center m-auto h-full">
        <h1 className="tracking-wide font-light">
          Terms <span className="font-bradford">and</span> Conditions
        </h1>
      </div>
    </HeroSection>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 py-8 px-6">
        <h2 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          Privacy Policy
        </h2>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Your privacy is important to Modal Living, Inc. (“Modal”). That’s why
          we’ve developed a straightforward Privacy Policy that outlines how we
          collect and use your information. Please take a moment to review this
          policy, and don’t hesitate to contact us if you have any questions. By
          supplying information to us or using our website, product, or
          services, you agree to the terms and conditions of this Privacy
          Policy.
        </p>
      </div>

      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          COLLECTION OF PERSONAL INFORMATION & USE
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          When you visit Modal’s website, with the help of our third-party
          software and service providers, we collect information (such as IP
          address, domain name, browser type, device type, broad geographical
          information, site-navigation pattern, etc.).We may use this data for
          research and commercial purposes, and to help us develop a better
          website and services that are more useful to you.
        </p>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          We collect certain personally identifiable information when you enter
          your address, email, billing information, phone number, name, or any
          other information you voluntarily provide, such as when signing up for
          email updates, scheduling a property survey.
        </p>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          We may use your email address, mailing address, or phone number to
          contact you to respond to your inquiries, provide updates or
          newsletters, or to provide information on products, news, or services
          to you. You can elect not to receive emails from us either by
          "unsubscribing" to an email you receive or by contacting us.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          SECURITY OF YOUR INFORMATION
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Our goal is to provide you with a safe and convenient online
          experience. We use reasonable technical, administrative and physical
          measures to protect information contained in our system against
          misuse, loss or alteration.
        </p>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          No method of electronic transmission or storage is 100% secure or
          error free. Therefore, we cannot guarantee absolute security of your
          information. If you have reason to believe that your interaction with
          us is no longer secure, please immediately notify us of the problem.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          ACCESS TO INFORMATION
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Customers of Modal in certain states can request certain information
          regarding our disclosure of personal information to third parties for
          their direct marketing purposes. To make such a request, please write
          to us at the following address:
          <p className="pt-6">
            Modal Living, Inc.<br></br> Legal 150 S State Street #100<br></br>{" "}
            Murrieta, CA 92562
          </p>
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          CHILDREN
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Children under the age of 13 are not permitted to use or access our
          website at any time or in any manner. If you have reason to believe
          that a child under the age of 13 has provided any personally
          identifiable information to us, please contact us, and we will try to
          remove that information from our databases.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          LINKS TO OTHER SITES
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          This Privacy Policy applies only to your use of Modal’s website. For
          your convenience and improved experience, Modal’s website may contain
          links to other websites that we do not operate or control. Because we
          cannot control what other organizations do on their websites, this
          Privacy Policy does not apply to these websites, and our inclusion of
          these links on our website does not imply that we endorse or have
          reviewed these other sites. We suggest contacting those sites directly
          for information on their privacy policies.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white pb-12">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          CHANGES TO POLICY
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          We reserve the right, at our sole discretion, to change, modify, add
          or remove any portion of our website or this Privacy Policy, in whole
          or in part, at any time. Changes to the Privacy Policy will be
          effective when posted. Your continued use of Modal’s website after any
          changes to the Privacy Policy are posted here will be considered
          acceptance of those changes.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 py-8 px-6 border-t">
        <h2 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          Terms of Use
        </h2>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Copyright 2019 Modal Living, Inc. All Rights Reserved. The text,
          images, symbols, illustrations, graphics, sound files, animation
          files, code, video files and their arrangement on Modal Living, Inc.
          internet sites are all subject to copyright and other intellectual
          property protection. These objects may not be copied for commercial
          use or distribution, nor may these objects be modified or reposted.
        </p>
      </div>

      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          TRADEMARKS
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Unless otherwise indicated, all marks displayed on Modal’s websites
          are subject to the trademark rights of Modal Living, Inc., including,
          but not limited to, corporate logos and colors.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          PRICES
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          All prices specified are current at the time of publication and
          subject to change without notice.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          NO LICENSES
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Our goal is to create a website that is informative and showcases
          Modal’s innovative process and building system. In order to achieve
          our mission, Modal must protect its intellectual property, including
          its patents, trademarks and copyrights. Neither our website, nor any
          material contained in it shall in any way grant or be taken to grant
          any person a license to Modal’s intellectual property.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          NO WARRANTIES OR REPRESENTATIONS
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          THE INFORMATION ON MODAL’S WEBSITES IS PROVIDED "AS IS" AND TO THE
          EXTENT PERMITTED BY LAW, IS PROVIDED WITHOUT WARRANTY OF ANY KIND,
          EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, OR
          NON INFRINGEMENT. WHILE THE INFORMATION PROVIDED IS BELIEVED TO BE
          ACCURATE, IT MAY INCLUDE ERRORS OR INACCURACIES.
        </p>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          MODAL’S WEBSITE MAY CONTAIN LINKS TO EXTERNAL SITES FOR YOUR
          CONVENIENCE. THESE EXTERNAL SITES ARE NOT UNDER MODAL’S CONTROL
          THEREFORE WE ARE NOT RESPONSIBLE FOR THE CONTENT OF ANY LINKED SITE.
        </p>
      </div>
    </section>

    <section className="relative text-left bg-white pb-10">
      <div className="xl:mx-48 lg:mx-24 px-6">
        <h4 className="lg:max-w-xlg m-auto pt-6 text-gray-600 tracking-wide font-light">
          Questions?
        </h4>
        <p className="text-gray-500 py-6 lg:px-0 m-auto tracking-wide font-light">
          Email us at legal@checksolarcost.com
        </p>
      </div>
    </section>
  </Layout>
)

export default Privacy
